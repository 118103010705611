@use  "../helpers/variables";
@use  "../helpers/mixins";

.odds {
  max-width: 600px;

  &__table {
    width: 100%;
    margin: 0 0 10px;

    &-cell {
      padding: 10px 15px;
      text-align: center;
      vertical-align: middle;
      font-weight: normal;
      font-size: 1.3rem;
      line-height: 1.7rem;
      color: rgba(0,0,0,.87);
      white-space: nowrap;
      position: relative;
      background: #ededed;
      border-bottom: solid 4px #f7f7f7;
      border-left: solid 1px #f7f7f7;

      &--head {
        padding: 5px 15px;
        font-size: 1.2rem;
        color: rgba(0,0,0,.54);
        background: none;
        border-left: none;
      }

      &--title {
        width: 70%;
        white-space: normal;
        text-align: left;
        font-size: 1.4rem;
        line-height: 2rem;
        border-left: none;
        background: #fff;
      }
    }

    &-time {
      position: absolute;
      top: 15px;
      right: 10px;
      font-size: 1rem;
    }
  }
}

