.landing {
  margin: 60px auto 0;

  @media (min-width: 768px) {
    margin: 70px auto 0;
  }

  background-color: #DC5B42;

  .hero__container {
    width: 100%;

    background-size: cover;
    background-position: center;

    .hero {
      max-width: 1100px;

      margin: 0 auto;

      background-position: right bottom;
  
      @media (min-width: 768px) {
        min-height: 50vh;
  
        &__title {
          width: 65%;
          font-size: 4.5rem;
          line-height: 5rem;
        }
      }
    }
  }

  .promo__section {
    max-width: 500px;

    padding: 0 25px;

    color: #fff;

    @media (min-width: 768px) {
      padding: 0;
    }

    .promo__title {
      margin: 30px 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1100px;

    margin: 0 auto;
  }

  @media (min-width: 768px) {
    .content--page--sign-up {
      padding: 80px 15px;
    }
  }

  .content--page--sign-up {
    padding: 20px 28px;
    background-color: #fff;

    @media (min-width: 768px) {
      margin: 15px 0;
      padding: 60px 60px 30px;

      border-radius: 8px;
    }
  }
}
