@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.promotions-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item-enter {
    opacity: 0;
    margin-top: 60px;
  }
  .item-enter-active {
    opacity: 1;
    margin-top: 20px;
    transition: opacity 0.8s ease-in-out, margin 0.4s ease-in-out;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 1s ease-in;
  }  
  .hero {
    background-position: right bottom;
    width: 100%;
    
    @media (min-width: 768px) {
      width: 49%;      
    }

    &__inner {
      padding: 0 30px 0 15px;
    }
    &__title--promo {
      font-size: 2rem;
      line-height: 2.3rem;
      width: 65%;

      @media (min-width: 768px) {
        font-size: 2.6rem;
        line-height: 3rem;
      }
    }
    @media (min-width: 768px) {
      font-size: 2.6rem;
      line-height: 3rem;
    }
  }
}

.promo {
  @include mixins.themify(themes.$themes) {
    background: mixins.themed('color');  
    color: mixins.themed('contentTextColor');
  }
  &__section {
    padding: 0 25px 25px;
    width: 100%;
    @media (min-width: 768px) {
      width: 70%;
    }
  }
  .hero {
    background-position: right bottom;

    @media (min-width: 768px) {
      min-height: 450px;

      &__title {
        width: 65%;
        font-size: 4.5rem;
        line-height: 5rem;
      }
    }
  }

  &__title,
  &__terms h1 {
    font-size: 3.5rem;
    line-height: 3.5rem;
    font-weight: 900;
    margin-top: 30px;

    @media (min-width: 768px) {
      line-height: 6.6rem;
      font-size: 5.0rem;
      margin: 50px 0 0;
    }
  }
  &__subtitle,
  &__terms h2 {
    line-height: 2.7rem;
    font-size: 2.5rem;
    margin: 30px 0;
  }
  &__description,
  &__terms p {
    font-size: 1.4rem;
    line-height: 2rem;
    margin: 25px 0;

    @media (min-width: 768px) {
      font-size: 1.6rem;
      line-height: 2.6rem;
    }
  }
  &__video-embed {
    padding-top: 30px;
    iframe {
      width: 100%;
      max-width: 450px;
      border-radius: 5px;
      margin-bottom: 30px;
    }
  }
}
