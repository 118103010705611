@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.checkbox {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  user-select: none;
  text-align: left;

  &__input {
    height: 100%;
    width: 100%;
    background-color: #eee;
    cursor: pointer;
  }

  .form__link {
    margin-left: 5px;
    cursor: pointer;

    @include mixins.themify(themes.$themes) {
      color: mixins.themed('linkColor');   
    }
  }

  .form__text {
    padding: 4px 10px 4px;
  }

  .form__field {
    width: 25px;
    height: 25px;
    flex: none;
  }

  .error {
    box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
    box-shadow: 0 0 5px #e60000;
  }
}
