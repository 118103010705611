@use  "../helpers/variables";

.age-verification {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
  color: variables.$white;
  text-align: center;
  &__container {
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  &__inner {
    padding: 2.6rem 1.6rem;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: space-between;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 500;
    -webkit-text-size-adjust: 100% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 90%;
  }

  &__text-wrapper {
    max-width: 950px;
    margin: 0 0 20px;
    p {
      font-size: 1.2em;
      line-height: 1.3em;
      @media (min-width: 768px) {
        font-size: 1.7em;
      }
    }
    
  }

  &__text {
    display: inline;

    p {
      display: inline;
    }
  }

  &__link {
    margin-left: 5px;
    color: variables.$buttonBackgroundColor;
  }
  &__button-wrapper {
    font-size: 1.4em;
    margin: 20px;
    button {
      display: block;
      margin-top: 20px;
    }
  }
  img {
    width: auto;
    height: 100%;
    position: absolute;
    left: -40%;
    @media (min-width: 768px) {
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
    }
  }
}
