@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.toggle {
  display: flex;
  align-items: center;

  gap: 16px;

  border: none;
  background: none;
  cursor: pointer;

  &--disabled {
    opacity: 0.5;
  }


  &__icon {
    position: relative;

    min-width: 34px;
    min-height: 14px;

    border-radius: 8px;
    margin: 0;
    background-color: #b6b6b6;

    &--on {
      background-color: #e8e0d0;
    }

    transition: background-color 0.2s ease-in-out;
  }

  &__pin {
    position: absolute;

    width: 20px;
    height: 20px;

    top: -3px;
    background-color: #e2e2e2;
    transform: translateX(calc(0% - 6px));

    &--on {
      transform: translateX(100%);

      @include mixins.themify(themes.$themes) {
        background-color: mixins.themed('toggleColor');
      }
    }

    transition: transform 0.2s ease-in-out;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 3px 0px;

    border-radius: 100%;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  &__title {
    letter-spacing: 0.5px;
    text-align: left;
    color: #000;
  }

  &__body {
    color: #767676;
    text-align: left;
  }
}
