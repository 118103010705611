@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.burger {
  &__overlay {
    background: rgba(0, 0, 0, 0.5);
    &--open {
      position: absolute;
      height: 100vh;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &__button {
    position: fixed;
    background: none;
    border: none;
    width: 30px;
    height: 30px;
    right: 5%;
    top: 15px;
    z-index: 9999;
    transition: all 0.3s ease;

    @media (min-width: 768px) {
      top: 20px;
    }

    span {
      transition: all 0.3s ease-in;
      opacity: 1;
    }

    .bars { 
      position: absolute;
      height: 3px;
      left: 2px;
      right: 2px;
      top: 5px;

      transition: all 0.2s ease-in-out;

      &:nth-child(2) {
        top: 13px;
      }
      &:nth-child(3) {
        top: 21px;
      }

      @include mixins.themify(themes.$themes) {
        background: mixins.themed('burgerMenuColor'); 
      }

      &--dark,
      &--subpage {     
        @media (min-width: 768px) {
          margin-top: -17px;
        }
      }
    }
    &--close {
      .bars {
        top: 14px;
        -webkit-transform: rotate(42deg);
        transform: rotate(42deg);
        transform-origin: center;
        transition: all 0.2s ease-in-out;
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          top: 14px;
          -webkit-transform: rotate(-42deg);
          transform: rotate(-42deg);
        }
      }
    }
  }

  &__logo {
    height: 50px;
    display: flex;
    justify-content: center;
    margin: 35px 0;

    .logo, .logo--nohover {
      height: 38px;
    }
  }

  &__wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 80%;
    height: 100vh;

    display: block;
    transform: translateX(-100%);

    &--open {
      box-shadow: 0 2px 15px rgb(0 0 0 / 20%);
      // opacity: 1;
      transition: all 0.2s ease-in-out;
      transform: translateX(0);
    }

    &--close {
      // opacity: 0;
      //right: 100%;
      transition: all 0.2s ease-in-out;
      pointer-events: none;
      transform: translateX(-100%);
    }

  }
  &__wrap,
  &__menu {
    .navigation {
      flex-direction: column;
      background: #fff;
      height: 100%;
    }

    .icon {
      display: flex;
      margin-right: 10px;
      width: 20px;

      img {
        width: 25px;

      }
    }

    @include mixins.themify(themes.$themes) {
      background: mixins.themed('mobileMenuBackground');
    }
    .navigation__item {
      margin: 0;
      padding: 20px 15px 20px 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .navigation__items {
      width: 100%;
      //padding: 20px 0;

      display: flex;
      flex-direction: column;

      //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      //align-items: flex-end;
    }

    .navigation__link {
      font-size: 1.6rem;
      text-transform: capitalize;
      opacity: 1;

      display: flex;
      align-items: center;
      
      color: #000;

      &--active {
        @include mixins.themify(themes.$themes) {
          color: mixins.themed('primaryColor');
        }
        font-weight: 600;
      }

    }

    .dropdown {
      width: 100%;
      right: 0;

      //padding: 20px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .language {
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        padding: 20px 15px 20px 28px;

        &__title {
          color: #000;
        }
      }
    }
    .dropdown-content {
      visibility: visible;
      opacity: 1;
      position: relative;
      background: none;
      box-shadow: none;
      margin: 0;

      &:after {
        content: none;
      }

      a.lang {
        color: #000;
        font-size: 1.4rem;
        padding: 18px 15px 18px 70px;

        border-top: 1px solid rgba(0, 0, 0, 0.03);

        &--active {
          font-weight: 600;
        }
      }
    }
  }

  &__menu {
    color: variables.$white;

    .navigation__items {
      align-items: center;
      width: 100%;
      margin-top: 150px;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .navigation__item {
      display: block;
      outline: none;
      margin: 0;
    }

    .navigation__link {
      text-transform: uppercase;
      font-size: 1.6rem;
      display: block;
      text-align: center;
      color: #000;

      &:hover,
      &:active {
        opacity: 0.7;
        color: variables.$gallery;
        text-decoration: none;
      }
    }
  }
}