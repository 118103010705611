@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.hero {
  position: relative;
  display: flex;
  align-items: center;
  color: variables.$white;
  @include mixins.themify(themes.$themes) {
    color: mixins.themed('bannerTextColor'); 
  }
  
  picture {
    width: 100%;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      display: block;
    }
  }

  &--type {
    &--pure {
      min-height: 200px;
    }

    &--promo {
      width: 100%;
      height: auto;
      border-radius: 5px;
      min-height: 170px;
      overflow: hidden;
      margin: 20px 0;
      box-shadow: 0px 3px 19px -4px rgba(0,0,0,0.86);
      &:last-child{
        margin-bottom: 20px;
      }
      @media (min-width: 768px) {
        min-height: 200px;
      }
      &:hover,
      &:focus {       
        &:before {
          background-color: rgba(0, 0, 0, 0);
        }
      }

      &:before {
        @include mixins.square(100%);
        @include mixins.pseudo;
        z-index: 2;
        transition: background-color 0.3s ease;
        background-color: rgba(0, 0, 0, 0.25);
      }

      @media (min-width: 768px) {
        min-height: 300px;
      }
    }

    &--main {
      width: 100%;
      position: relative;
      overflow: hidden;

      @media (min-width: 768px) {
        max-height: 75vh;
      }

      @media (min-width: 1280px) {
        max-height: 82vh;
      }

      @media (min-width: 1920px) {
        max-height: 800px;
      }
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &__inner {
    position: absolute;
    left: 0;
    right: 0;
    
    @media (min-width: 768px) {
      margin-top: 0;
    }

    &--promo {
      margin: initial;
    }
  }

  &__title {
    margin: 0 0 10px;
    font-size: 3.2rem;
    font-weight: bold;
    //line-height: 3.8rem;
    text-transform: uppercase;
    position: relative;
    z-index: 2;

    @include mixins.themify(themes.$themes) {
      font-family: mixins.themed('secondaryFont');  
      letter-spacing: mixins.themed('bannerH1LetterSpacing');
      text-transform: mixins.themed('bannerH1Transform');
      color: mixins.themed('bannerTitleColor'); 
    }

    @media (min-width: 768px) {
      font-size: 5rem;
      //line-height: 5rem;
      width: 45%;
    }

    &--promo {
      font-size: 2.2rem;
      //line-height: 2.8rem;
    
      @media (min-width: 768px) {
        font-size: 4.5rem;
        //line-height: 5rem;
        width: 50%;
      }
    }
    &--jp {
      @include mixins.themify(themes.$themes) { 
        text-shadow: mixins.themed('jpBannerTitleShadow'); 
      }
    }
  }

  &__subtitle {
    margin: 0 0 30px;
    font-size: 1.8rem;
    //line-height: 2.5rem;
    text-transform: capitalize;
    font-weight: 600;
    position: relative;
    z-index: 2;

    &--jp {
      @include mixins.themify(themes.$themes) { 
        color: mixins.themed('jpBannerSubTitleColor'); 
      }
    }

    @media (min-width: 768px) {
      font-size: 2.8rem;
      //line-height: 3rem;
      width: 45%;
    }
  }

  &__legal {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 1.1rem;
    line-height: 1.3rem;
    background: rgba(0, 0, 0, 0.64);

    @media (min-width: 768px) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    &-inner {
      padding: 3%;

      @media (min-height: 736px) {
        padding: 5%;
      }

      @media (min-width: 768px) {
        padding: 15px;
      }
    }
  }

  &__cover-link {
    @include mixins.square(100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
}
