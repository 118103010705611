@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.navigation {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  &__items {
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    @media (min-width: 768px) {
      margin: 0;
    }
  }

  &__item {
    margin: 0 0 0 15px;
    list-style: none;

    &:last-child {
      @media (min-width: 768px) {
        padding-right: 15px;
      }
    }
  }

  &__link {
    color: variables.$buttonTextColor;
    font-size: 1.5rem;
    text-transform: uppercase;
    opacity: 0.8;
    font-weight: 500;
    line-height: normal;

    @include mixins.themify(themes.$themes) {
      letter-spacing: mixins.themed('menuLinkLetterSpacing');
      font-weight: mixins.themed('menuLinkfontWeight');
    }

    @media (min-width: 768px) {
      font-weight: 600;
    }

    &:hover,
    &:active {
      opacity: 1;
      color: variables.$white;
      text-decoration: none;
    }

    &--active,
    &:focus {
      opacity: 1;
      color: variables.$white;
      text-decoration: none;
    }
  }

  &--theme--dark {
    .navigation__link {
      color: variables.$white;
    }
  }

  &--small {
    @media (min-width: 768px) {
      margin: 0 0 0 50px;
      margin: 0;
    }

    .navigation__items {
      flex-direction: column;
      @media (min-width: 768px) {
        flex-direction: row;
      }
    }

    .navigation__item {
      margin: 0 15px 10px;

      &:last-child {
        margin-bottom: 20px;
      }

      @media (min-width: 768px) {
        margin-bottom: 0;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .navigation__link {
      font-size: 1.4rem;
      text-transform: none;
    }
  }

  &--categories {
    position: relative;
    margin: 10px 0;
    align-items: unset;
    padding-bottom: 10px;

    @media (max-width: 768px) {
      overflow-x: scroll;
      overflow-y: hidden;
      padding-left: 15px;
    }

    &:after {
      background: variables.$black;
      height:10px;
      width: 10px;
      position: absolute;
      right: 0;
    }

    @media (min-width: 768px) {
      justify-content: center;
    }

    .navigation__items {
      display: flex;
      flex-direction: row;

      margin: 0;
      margin-bottom: 10px;

      .navigation__link, .navigation__item {
        margin: 0;
      }

      .navigation__item:not(:last-child) {
        margin-right: 5px;

        @media (min-width: 768px) {
          margin-bottom: 5px;
        }
      }
      
      @media (min-width: 768px) {
        position: inherit;

        display: flex;
        flex-wrap: wrap;

        padding-right: 0;
      }
    }
    .navigation__link {
      display: block;

      margin: 0 7px;
      padding: 9px 16px;
      font-weight: 700;
      font-size: 1.3rem;
      letter-spacing: 0.03rem;
      opacity: 0.8;
  
      @include mixins.themify(themes.$themes) {
        border-radius: mixins.themed('ctaBorderRadius');
        color: mixins.themed('ctaTextColor');
        font-size: mixins.themed('categoriesFontSize');
  
      }

      white-space: nowrap;

      @include mixins.themify(themes.$themes) {
        background: mixins.themed('navigationCtaBg'); 
      }
      
      @media (min-width: 768px) {
        padding: 6px 16px;
      }
  
      &:hover,
      &:active {
        text-decoration: none;
        @include mixins.themify(themes.$themes) {
          opacity: 1;
        }
      }
  
      &--active,
      &:focus {
        @include mixins.themify(themes.$themes) {
          opacity: 1;
        }
        text-decoration: none;
      }
    }
  }

  &::-webkit-scrollbar { 
    display: none;
}
}
