@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

@font-face {
  font-family: RobotoExtraBlack;
  font-display: swap;
  src: url('../../assets/fonts/Roboto/Roboto-ExtraBlack.ttf');
}
@font-face {
  font-family: BrownStd-Light;
  font-display: swap;
  src: url('../../assets/livecasino/fonts/BrownLLWeb-Light.woff') format('woff');
}
@font-face {
  font-family: Grold-Regular;
  font-display: swap;
  src: url('../../assets/gogocasino/fonts/grold-regular-webfont.woff') format('woff');
}
@font-face {
  font-family: Grold-Light;
  src: url('../../assets/gogocasino/fonts/grold-light-webfont.woff') format('woff');
}
@font-face {
  font-family: Grold-Medium;
  src: url('../../assets/gogocasino/fonts/grold-medium-webfont.woff') format('woff');
}
@font-face {
  font-family: Wulkan-Display;
  font-display: swap;
  src: url('../../assets/livecasino/fonts/WulkanDisplay-Bold.woff') format('woff');
}
@font-face {
  font-family: Black-Slabbath;
  font-display: swap;
  src: url('../../assets/gogocasino/fonts/Black-Slabbath.woff') format('woff');
}
@font-face {
  font-family: Barlow;
  font-display: swap;
  src: url('../../assets/pinkcasino/fonts/BarlowCondensed-Regular.woff') format('woff');
}

@font-face {
  font-family: Hind;
  src: local('Hind Medium'), local('Hind-Medium'),
      url('../../assets/expekt/fonts/Hind-Medium.woff2') format('woff2'),
      url('../../assets/expekt/fonts/Hind-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Hind;
  src: local('Hind Light'), local('Hind-Light'),
      url('../../assets/expekt/fonts/Hind-Light.woff2') format('woff2'),
      url('../../assets/expekt/fonts/Hind-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Klavika Bd;
  src: local('Klavika-Bold'),
      url('../../assets/expekt/fonts/Klavika-Bold.woff2') format('woff2'),
      url('../../assets/expekt/fonts/Klavika-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-display: swap;
  font-family: Kanit;
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/royalpanda/fonts/Kanit-Medium.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: Kanit;
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/royalpanda/fonts/Kanit-Bold.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  min-height: 100%;
  font-size: 62.5%;
}

body {
  height: 100%;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: normal;
  margin: 0;
  padding: 0;
  @include mixins.themify(themes.$themes) {
    font-family: mixins.themed('font'), sans-serif;   
    background: mixins.themed('pageBackground');
    letter-spacing: 0.28px;
  }
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

a {
  text-decoration: none;
}

iframe {
  max-width: 100%;
  border: none;
}

.flex-centered {
  display: flex;
  align-items: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.centered {
  text-align: center;
}

.vertical-indent {
  margin: 30px 0;
}

.horizontal-padding {
  padding: 0 25px;

  &__mob {
    @media (max-width: 768px) {
      padding: 0 15px;
    }
  }
}

.inner {
  max-width: 1280px;
  margin: 0 auto;
}
