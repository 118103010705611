@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.label {
  margin: 20px 0;

  @include mixins.themify(themes.$themes) {
    text-align: mixins.themed('labelAlign');
  }

  @media (min-width: 768px) {
    margin-bottom: 30px;
  }

  &__text {
    text-transform: uppercase;
    position: relative;

    @include mixins.themify(themes.$themes) {
      color: mixins.themed('labelTextColor');
      font-size: mixins.themed('titleSize');
      font-weight: mixins.themed('titleWeight');   
      text-transform: mixins.themed('titleStyle');
      font-family: mixins.themed('labelFont');
    }
  }
}
