@use  "themes";
@use  "../helpers/variables";

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin pseudo() {
  content: "";
  position: absolute;
  top: 0;
  z-index: 2;
}

@mixin button($color) {
  background: $color;
  border-color: $color;

  &:hover, &:focus {
    color: $color;
  }
}

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin font($themes) {
  @each $theme, $map in $themes {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
  }
}
