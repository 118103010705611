@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.cta {
  border: none;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  font-weight: 500;
  font-size: 1.5rem;
  padding: 17px 60px;
  margin: 0 auto;
  box-shadow: 0 5px 15px rgba(0,0,0,0.20);
  text-decoration: none;

  a {
    @include mixins.themify(themes.$themes) {
      color: mixins.themed('ctaTextColor');
    }
  }

  @include mixins.themify(themes.$themes) {
    background-color: mixins.themed('ctaColor'); 
    border-radius: mixins.themed('ctaBorderRadius');
    color: mixins.themed('ctaTextColor');
    padding: mixins.themed('ctaTextMargin');
  }

  &__text {
    position: relative; 
    z-index: 1;
  }

  &:hover {
    transform: scale(1.1);
    transition: 0.3s;
  }

  &--color--red {
    @include mixins.button(red)
  }

  &--color--blue {
    @include mixins.button(blue)
  }

  &--color--green {
    @include mixins.button(green)
  }

  &--color--orange {
    @include mixins.button(orange)
  }

  &--size {
    &--wide {
      width: 100%;
      padding: 15px 30px;
      font-size: 1.7rem;
    }

    &--large {
      padding: 15px 30px;
      font-size: 1.7rem;
    }

    &--small {
      padding: 5px 10px;
      font-size: 1.0rem;
    }

    &--medium {
      padding: 15px 20px;
      font-size: 1.2rem;
    }
  }

  &--rounded {
    @include mixins.themify(themes.$themes) {
      border-radius: mixins.themed('ctaBorderRadius');
    }
  }
  &--gallery {
    background-color: variables.$alabaster;
    color: variables.$scorpion;
    border: 1px solid variables.$ghost;
    margin: 40px 0;
    @include mixins.themify(themes.$themes) {
      border-radius: mixins.themed('ctaBorderRadius');
    }
  
    &:hover {
      background: variables.$white;
    }
  }
  &--hidden {
    display: none;
    opacity: 0;
    transition: all 1s ease-in-out;
  }
  &--gogocasino {
    font-family: Grold-Medium;
    font-size: 1.5rem;
    letter-spacing: 1.25px;
  }
  &--livecasino {
    &:before{
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      content: "";
      position: absolute;
      background: transparent;
      border-color: #031c1c;
      border-style: solid;
      border-width: 2px;
    }
  }
}
