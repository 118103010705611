@use  "../helpers/variables";
@use  "../helpers/mixins";

.scroll-indicator {
  display: block;
  width: 30px;
  height: 50px;
  border-radius: 14px;
  border: 1px solid variables.$white;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  z-index: 10;
  top: 77vh;
  box-shadow: 0 3px 6px rgba(0,0,0,0.33);

  &--hidden {
    display: none;
  }

  &__scrollwheel {
    display: block;
    margin: 5px auto;
    width: 3px;
    height: 6px;
    border-radius: 3px;
    background: variables.$white;
    border: 1px solid transparent;
    animation: scrollwheel 1.1s both infinite;
  }
}

@keyframes scrollwheel {
  0% {
    opacity: 1;
    transform: translateY(-5px);
	}
  100% {
    opacity: 0;
    transform: translateY(15px);
	}
}
