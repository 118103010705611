@use  "../helpers/mixins";
@use "../helpers/themes";

.games-list {
  margin-bottom: 40px;

  @media (min-width: 768px) {      
    margin: 30px 0;
  }

  &__inner {
    padding-left: 0;
    padding-right: 0;
    flex-flow: wrap;
    justify-content: center;
    display: flex;
    gap: 15px;

    @media (min-width: 768px) {      
      flex-flow: wrap;
      justify-content: flex-start;
      display: flex;
      gap: 15px;
    }
  }
  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    @media (min-width: 768px) {      
      justify-content: flex-start;
    }

    a {
      //margin: 40px 0 20px;
      font-size: 1.4rem;
      font-weight: 600;
      padding: 10px;
      display: flex;
      align-items: center;

      @media (min-width: 768px) {      
        padding: 10px 20px;
      }

      &:after {
        position: relative;
        left: 0;
        transition: left 0.4s;

        @include mixins.themify(themes.$themes) {
          content: "";
          width: 15px;
          height: 15px;
          background: mixins.themed('chevronIcon');   
          background-repeat: no-repeat;
        }
      }

      @include mixins.themify(themes.$themes) {
        font-size: mixins.themed('showAllLinkFontSize');
        color: mixins.themed('showAllLinkColor');   
        text-transform: mixins.themed('showAllLinkTransform');
        border: mixins.themed('showAllLinkBorder');
        border-radius: mixins.themed('showAllLinkBorderRadius');
        font-weight: mixins.themed('showAllLinkfontWeight');
      }
      
      &:hover {
        opacity: 0.9;
    
        &:after {
          left: 10px;
          transition: left 0.4s;
        }
      }

      span {
        margin-right: 5px;
      }
    }

    &.has-description {
      display: block;
      
      @media (min-width: 768px) {
        margin-right: 70px;
      }

      .category-link {
        margin: 10px;
        display: flex;
        justify-content: flex-end;
      }

      a {
        margin: 0;
      }
    }
  }
}

.description {
  margin: 10px;
  line-height: 1.5;
  @include mixins.themify(themes.$themes) {
    color: mixins.themed('contentTextColor');
  }

  p:not(:last-child){
    margin-bottom: 10px;
  }
}