@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.search {
  width: 100%;
  margin-top: 20px;
  @media (min-width: 768px) {
    width: 600px;
    margin: 0 auto;
  }
  padding: 15px;
  &__input {
    font-size: 1.8rem;
    width: 100%;
    // box-shadow: 0px 5px 15px 0px rgba(253,91,59,0.25);
    padding: 15px 25px;

    @include mixins.themify(themes.$themes) {
      border: mixins.themed('searchBarBorder'); 
      background: mixins.themed('searchBarBg');
      color: mixins.themed('searchTextColor');
      border-radius: mixins.themed('ctaBorderRadius');  
      background-position: right 15px center;
      background-size: 20px;
    }
    

    &:focus {
      outline: none;
      background: none;
    }
  }
}
