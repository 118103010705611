.hero-swiper {

  .swiper-container, .swiper-wrapper {
    height: 75vh;

    @media (min-width: 768px) {
      height: 75vh;
    }
  }

  .swiper-slide-active:after {
    content: "";
    animation: progress 5s ease-in-out forwards;
    height:3px;
    position: absolute;
    bottom:0;
    left:0;
    background: rgba(255,255,255,0.6);

    @media (min-width: 768px) {
      height:7px;
    }
  }
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
