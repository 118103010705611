@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.content-section {
  width: 100%;
  @include mixins.themify(themes.$themes) { 
    background-color: mixins.themed('contentBgColor');
    border-top: mixins.themed('contentTopBorder');
  }
  padding-bottom: 30px;
  @media (min-width: 768px) {
    padding: 30px;
  }

  &__inner {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    max-width: 1280px;
    margin: 0 auto;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  &__column {
    flex: 1 1 auto;
    width: 100%;
    align-self: center;
    padding: 20px 20px 0;

    @include mixins.themify(themes.$themes) { 
      color: mixins.themed('contentTextColor');
    }

    .image {
      text-align: center;
    }
    @media (min-width: 768px) {
      width: 50%;
    }
    h2 {
      font-size: 2.8rem;
      line-height: 3.5rem;
      font-weight: bold;
      margin: 20px 0;
    }
    h3 {
      font-size: 2rem;
      line-height: 2.5rem;
      font-weight: bold;
      margin: 10px 0;
    }
    p {
      font-size: 1.5rem;
      line-height: 2.2rem;
      margin: 15px 0;
    }
    ul {
      padding-left: 40px;
    }
    li {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
    a {
      border: none;
      display: block;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      outline: none;
      overflow: hidden;
      position: relative;
      font-weight: 700;
      font-size: 1.5rem;
      padding: 17px 30px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      text-decoration: none;
      margin: 40px 20px;
      @include mixins.themify(themes.$themes) {
        background-color: mixins.themed('ctaColor'); 
        border-radius: mixins.themed('ctaBorderRadius');
        color: mixins.themed('ctaTextColor');
      }
      @media (min-width: 768px) {
        margin: 20px 0;
        padding: 17px 60px;
        display: inline-block;
      }
      &:hover {
        transition: all 0.3s ease-in;
        @include mixins.themify(themes.$themes) {
          color: mixins.themed('ctaTextColor');
          background: mixins.themed('ctaHoverColor'); 
        }
      }
    }
  }
  &__image {
    width: 100%;
    vertical-align: middle;

    @media (min-width: 768px) {
      width: 80%;
    }
  }
}
.content-section:nth-child(even) {
  @include mixins.themify(themes.$themes) { 
    background-color: mixins.themed('contentBgColorEven');
  }
  .content-section__inner {
    flex-direction: column-reverse;
    @media (min-width: 768px) {
      flex-direction: row-reverse;
    }
  }
}
