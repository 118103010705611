@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.content {
  padding: 30px 25px;

  @media (min-width: 768px) {
    padding: 50px 15px 15px;
  }

  &__container {
    max-width: 500px;
    margin: 0 auto;
    background: rgba(255,255,255,0.9);
    border-radius: 4px;
    padding: 30px 15px;
    @media (min-width: 768px) {
      padding: 30px 0;
    }
  }

  &--page--sign-up {
    @media (min-width: 768px) {
      padding: 40px 15px;
    }
  }

  &--page--games {
    padding: 5px 0;

    @media (min-width: 768px) {
      padding: 50px 15px 15px;
    }
  }

  &--page--promotion {
    padding: 90px 15px 60px 15px;

    @media (min-width: 768px) {
      padding: 120px 15px 15px;
    }
  }

  &__title {
    margin: 20px 0;
    font-size: 3rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  &__sub-title {
    margin: 20px 0;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  h2, h3 {
    line-height: 2.4rem;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin: 25px 0;
  }

  ul,
  ol {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin: 0 30px 10px;

    li {
      margin: 0 0 5px;
    }
  }

  img {
    max-width: 100%;
  }

  a {
    @include mixins.themify(themes.$themes) {
      color: mixins.themed('primaryColor');   
    }
   }
  
  
   a.cta  {
      @include mixins.themify(themes.$themes) {
        color: mixins.themed('ctaTextColor');   
      }
     }
}
