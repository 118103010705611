@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.form {
  margin: 0 0 50px;
  display: flex;
  flex-direction: column;
  text-align: center;

  &__input-wrapper {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 380px;
    margin-top: 30px;
  }

  &__field {
    position: relative;
  }

  &__label,
  &__error {
    position: absolute;
    top: 1.5rem;
    left: 15px;
    font-size: 1.6rem;
    color: variables.$manatee;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all .2s ease;
    pointer-events: none
  }

  &__border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: variables.$mineShaft;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 0.15s ease;
  }

  &__input {
    -webkit-appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 12px;
    height: 48px;
    font-size: 1.6rem;
    font-weight: 500;
    // border-bottom: 2px solid $ghost;
    background: none;
    border-radius: 5px;
    color: variables.$mineShaft;
    transition: all .15s ease;
    border: 1px solid rgba(0, 0, 0,0.3);
    
    @include mixins.themify(themes.$themes) {
      color: mixins.themed('textColor'); 
      background: mixins.themed('signUpFieldsBg');  
    }

    &:not(:placeholder-shown) ~ .form__label {
      color: variables.$comet;
      transform: translateY(-35px) scale(0.75);
    }

    &:focus {
      outline: none;
    }

    &:focus ~ .form__label,
    &:focus ~ .form__error {
      transform: translateY(-35px) translateX(-10px) scale(0.75);
    }

    &:focus ~ .form__border {
      transform: scaleX(1);
    }
  }

  .error {
    border: 1px solid variables.$red;
    box-shadow: 0 0 5px #e60000;
    border-radius: 5px;
  }

  &__error {
    color: variables.$red;
    z-index: 1;
    transform: translateY(-38px) translateX(-10px)  scale(0.75);
    right: 0;
    left: auto;
    top: 55px;
  }

  &__link {
    margin: 0 0 0 5px;
    font-weight: bold;

    @include mixins.themify(themes.$themes) {
      color: mixins.themed('linkColor');   
    }
  }

  &__consent {
    margin: 20px auto;
    padding: 10px;
    width: 100%;
    max-width: 380px;

    .form__error {
      display: none;
    }
  }
}

.skip-signup {
  display: block;
  margin: 30px auto 0;
  text-align: center;
  color: #a5a5a5;
}
