@use  "../helpers/variables";
@use  "../helpers/mixins";

.media {
  &__preview {
    &--mobile {
      margin: 50px 0 0px 20px;
      max-width: 580px;
      height: 280px;
      img {
        height: 100%;
        width: 100%;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.31) 0 10px 30px 0, 3px 5px 20px rgba(255, 255, 255, 0.8);
      } 
      .swiper-container {
        height: 100%;
        width: 100%;
        .swiper-slide-next {
          border-radius: 5px;
          overflow: hidden;
          left: -30%;
          top: 20px;
          max-height: 240px;
          transition: all 0.4s ease-in-out;
          img {
            width: 100%;
            transition: all 0.4s ease-in-out;
          }
        }
        .swiper-slide-active, .swiper-slide-prev {
          left: 0;
          top: 0;
          max-height: 400px;
          transition: all 0.4s ease-in-out;
          img {
            width: 90%;
            transition: all 0.4s ease-in-out;
          }
        }
        .swiper-slide-prev {
          img {
            width: 100%;
            transition: all 0.4s ease-in-out;
          }
        }
      }
    }
    &__container {
      position: relative;
      width: 580px;
      height: 350px;
      margin-top: 40px;
    }
    &__cta {
      width: 100%;
      text-align: center;
    }
    &--desktop{
      display: flex;
      align-items: stretch;
      margin-top: 40px;
      justify-content: center;
      flex-wrap: wrap;
    }
    &-item {
      &--desktop{
        width: 20%;
        height: auto; 
        position: relative;
        max-height: 230px;
        display: inline-flex;
        margin: 1%;
        img {
          border-radius: 5px;
          cursor: pointer;
          width: 100%;
          height: 100%;
        }
      }
      &--mobile{
        overflow: hidden;
        left: -115%;
        top: 50px;
        max-height: 180px;
        transition: all 0.4s ease-in-out;
        width: 100%;
        .cta--gallery {
          margin-top: 0;
        }
      }
      img {
        width: 100%;
        transition: all 0.4s ease-in-out;
      }
    }
   }
  &__gallery {
    justify-content: center;
    background: variables.$alabaster;
    flex-wrap: wrap;
    display: none;
    align-items: stretch;
    @media (min-width: 768px) {
      display: flex;    
      margin-top: 30px;
    }
    &--visible, &--preview {
      display: flex;
      margin: 40px 0;
      align-items: stretch;
      opacity: 1;
      transition: opacity 1s ease-in-out;
      @media (min-width: 768px) {
        margin: 0;    
      }
    }
    &--preview {
      margin-top: 40px
    }
    &--hidden {
      display: none;
    }
    &-thumbs {
      height: calc(( (100vw) / 4) - 20px);
      flex-basis: 20%;

      @media (min-width: 768px) {
        width: 20%;
        height: auto; 
        flex-basis: initial;
        max-height: 230px;
      }
      
      position: relative;
      display: inline-flex;
      margin: 1%;
      img {
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        cursor: "pointer";
        position: "absolute";
        max-width: "100%"
      }
    }
  }
}
.fullscreen {
  z-index: 9999;
  position: fixed;
  .react-images__track {
    height: inherit;
  }
}
