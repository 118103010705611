@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.card {
  transition: transform 0.2s ease;
  overflow: hidden;

  &__thumbnail {
    width: 165px;
    height: 126px;
    background-size: cover;

    @media (max-width: 360px) {  
      width: 130px;
      height: 99px;
    
    }

    @media (min-width: 512px) {  
      width: 235px;
      height: 180px;
    
    }
  }

  &__thumbnail-link {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 200%;
      width: 200%;
      background: variables.$white;
      transition: all .5s ease-in-out;
      transform: translateX(-128%) translateY(25%) rotate(45deg);
      opacity: 0.1;
    }
  
    &:hover{
      &:after {
        transform: translateX(-20%) translateY(-25%) rotate(45deg);
      }
    }
  }

  &__image {
    border-radius: 5px;
    max-width: 100%;
    box-shadow: 0 2px 7px rgb(0 0 0 / 15%);
    
  }

  &__info {
    padding: 10px 0;
  }

  .card__title {
    text-align: left;
    color: #575757;
    font-size: 1.3rem;
    line-height: 1.5rem;
    font-weight: 300;
    margin: 0;

    width: 165px;

    @media (max-width: 360px) {  
      width: 130px;
    
    }

    @media (min-width: 512px) {  
      width: 235px;
    
    }
  
    @media (min-width: 768px) {    
      font-size: 1.4rem;
    }
  }
}
