@use  "../helpers/variables";
@use  "../helpers/mixins";

.toast {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.4);

  display: none;

  &--visible {
    display: block;
  }

  &__inner {
    max-width: 550px;
    width: 92%;
    box-sizing: border-box;
    transform: translate(-50%);
    position: absolute;
    top: 25%;
    left: 50%;
    z-index: 1000;
    color: #fff;
    background-color: #FF9800;
    
    border-radius: 5px;
    box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
    display: none;
    font-weight: 600;

    &--visible {
      display: flex;
    }

    &--success {
      background: #0dcc74;
    }

    &--error {
      background: #ce1609;
    }
  }

  &__header {
    padding: 5px 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 1.5rem;
  }

  &__close-button {
    width: 40px;
    height: 40px;

    position: absolute;
    top: 10px;
    right: 10px;

    background-image: url("../../assets/images/close-icon.svg");
    background-color: transparent;
    background-position: 50%;
    background-size: 100%;

    border: none;

    cursor: pointer;
  }

  &__body {
    padding: 40px 25px;
    border-radius: 5px;
    font-size: 1.7rem;
    font-weight: 100;
    line-height: 2.5rem;
    text-align: center;   

    h3, h4 {
      font-weight: bold;
      font-size: 1.9rem;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: none;

    &--visible {
      display: block;
    }
  }
}
