@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

@keyframes slideUpAnim {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.cookie-consent {
  position: fixed;
  inset: 0;
  z-index: 999;

  display: flex;
  flex-direction: column-reverse;

  background-color: rgba(0, 0, 0, 0.65);

  .cta {
    @media (max-width: 512px) {
      width: 100%;
      padding: 10px 20px;
    }
    @include mixins.themify(themes.$themes) {
      background: mixins.themed('cookiesBtnColor'); 
      border: mixins.themed('cookiesBtnBorder'); 
      color: mixins.themed('cookiesBtnTextColor'); 
    }
    &:hover {
      transform: scale(1.1);
      transition: 0.3s;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;

    background-color: #fff;

    animation: slideUpAnim 0.25s ease-in-out forwards;
  }

  &__infopanel {
    display: flex;
    flex-direction: column;

    padding: 20px;
    gap: 16px;

    @media (min-width: 900px) {
      flex-direction: row;
    }

    svg {
      min-width: 70px;
      min-height: 70px;
    }

    svg > rect {
      fill: #f1f0ec;
    }

    svg > path {
        fill: #000;
      }
    }

  &__disclaimer {
    display: flex;

    gap: 16px;

    @media (min-width: 900px) {
      max-width: 60%;
    }
  }

  &__text {
    display: inline-block;
    flex-grow: 1;
    font-size: 1.5rem;
    color: #000;
    line-height: 1.2;

      button {
        text-decoration: none;
        border: none;
        background: none;
        color: #008c70;
        font-size: 1.5rem;
        cursor: pointer;
        font-size: 1.4rem;
        line-height: normal;
        margin: 0;
        padding: 0;
        @include mixins.themify(themes.$themes) {
          font-family: mixins.themed('font'), sans-serif;  
          letter-spacing: 0.28px;
        }
      }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;

    gap: 16px;

    @media (min-width: 600px) {
      display: flex;
    }

    &--open {
      display: none;
      @media (min-width: 600px) {
        display: flex;
      }
    }

    .cta {
      font-size: 1.4rem;
      margin: 0;
    }
  }

  &__managementbuttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;

    .cta {
      width: 100%;
    }

    .cta {
      width: 100%;
    }

    @media (min-width: 600px) {
      display: none;
    }
  }

  &__managepanel {
    display: flex;
    flex-direction: column;


    border-top: grey 1px solid;

    padding: 16px;
  }

  &__toggles {
    display: grid;
    grid-template-columns: 1fr;

    gap: 12px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1280px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
