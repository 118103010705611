@use  "../helpers/mixins";
@use "../helpers/themes";

.dropbtn {
  position: relative;
  padding: 0 15px;
  margin-left: 20px;
  border: none;
  cursor: pointer;
  opacity: 1;

  span {
    font-size: 1.1em;
    font-weight: 600;
    margin-right: 5px;
    text-transform: uppercase;
    color: white;
    vertical-align: middle;
  }
  img {
    max-width: 26px;
  }
  &:after {
    content: "";
    width: 0; 
    height: 0; 
    position: absolute;
    right: 0px;
    bottom: 30px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: opacity 0.2s ease-in;
    @media (max-width: 768px) {
      content: none;
    }
  }
  @media (min-width: 768px) {
    padding: 19px 15px;
    opacity: 0.8;
  }
  &:hover {
    opacity: 1;
    &:after {
      opacity: 1;
    }
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;

  @media (max-width: 769px) {
    right: 30px;
  }

  &--menu-visible {
    .dropbtn {
      opacity: 1;
      
      &:after {
        opacity: 0;
        transition: opacity 0.2s ease-in;
      }
    }
    .dropdown-content {
      visibility: visible;
      opacity: 1;
      transition: all 0.2s ease-in;
    }
  }

}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  border-radius: 2px;

  background-color: #f7f7f7;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 20%);
  z-index: 1;
  padding: 5px;
  margin-top: 20px;
  min-width: 150px;
  right: 0;
  border-radius: 4px;

  transition: all 0.2s ease-in;

  @media (min-width: 769px) {
    margin-top: 10px;
  }

  &:after {
    content: " ";
    position: absolute;
    right: 30px;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #f7f7f7;;
  }
}

/* Links inside the dropdown */
.dropdown-content a.lang {
  padding: 15px 30px;
  font-size: 0.9em;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  color: rgba(0,0,0,0.65);
  transition: all 0.2s ease-in;

  span:nth-child(2) {
    text-align: right;
    margin-left: 5px;
    text-transform: uppercase;
  }

  &--active {
    color: #000;
    background-color: rgba(255, 255, 255, 1);
    transition: all 0.3s ease-in;
  }
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    transition: all 0.2s ease-in;

    @include mixins.themify(themes.$themes) {
      background: mixins.themed('dropdownHoverBg');
    }
}
