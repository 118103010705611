@use  "../helpers/mixins";
@use "../helpers/themes";

.games-swiper {
  margin: 25px 0;

  .card {
    margin: 0;
    margin-left: 15px;
    margin-right: 0 !important;
  
    &__thumbnail {
      width: 135px;
      height: 103px;

      @media (max-width: 360px) {  
        width: 130px;
        height: 90px;
      
      }

      @media (min-width: 512px) {  
        width: 235px;
        height: 180px;
      
      }
    }

    &__title {
      width: 135px;

      @media (max-width: 360px) {  
        width: 130px;
      }

      @media (min-width: 512px) {  
        width: 235px;
      
      }
    }
  }

  .label {     
      margin-left: 15px;
  }

  &__category {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    a {
      font-size: 1.4rem;
      position: relative;
      font-weight: 600;
      display: inline-block;
      margin: 0 10px;
      display: flex;
      align-items: center;

      &:after {
        @include mixins.themify(themes.$themes) {
          content: "";
          width: 15px;
          height: 15px;
          margin-left: 5px;
          background: mixins.themed('chevronIcon');   
        }
      }

      @include mixins.themify(themes.$themes) {
        color: mixins.themed('showAllLinkColor');   
        text-transform: mixins.themed('showAllLinkTransform');
        border: mixins.themed('showAllLinkBorder');
        border-radius: mixins.themed('showAllLinkBorderRadius');
      }

      &:hover {

        @include mixins.themify(themes.$themes) {
          color: mixins.themed('showAllLinkHoverColor'); 
          background: mixins.themed('showAllLinkHoverBackground'); 
          text-decoration: mixins.themed('showAllLinkHoverDecoration');
        }

      }
      @media (min-width: 768px) {
        margin-right: 70px;
      }
  }
    
  }
  .swiper-container {
    margin-bottom: 10px;
  }
}
