@use "../helpers/variables";
@use "../helpers/mixins";
@use "../helpers/themes";

.page {
  position: relative;

  &__header {
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    transition: all 0.1s ease;
    box-shadow: rgba(0,0,0,0.2) 0px 2px 15px;

    @media (min-width: 768px) {
      height: 64px;
    }
    
    @include mixins.themify(themes.$themes) {
      color: mixins.themed('textColor');  
      background: mixins.themed('headerBackground');  
    }
  }

  &__body {
    min-height: calc(100vh - 142px);
    box-sizing: border-box;
    margin-top: 60px;

    @include mixins.themify(themes.$themes) {
      background: mixins.themed('pageBackground');
      color: mixins.themed('textColor');    
    }

    @media (min-width: 768px) {
      min-height: calc(100vh - 100px);
      margin-top: 64px;
    }
  }

  &__footer {
    width: 100%;
    min-height: 100px;
    padding: 30px 0 20px;
    box-sizing: border-box;
    color: variables.$footerColor;
    text-align: center;

    @include mixins.themify(themes.$themes) {
      color: mixins.themed('footerTextColor');
      border-top: mixins.themed('footerBorder');   
      background: mixins.themed('footerBgColor'); 
    }

    a {
      @include mixins.themify(themes.$themes) {
        color: mixins.themed('footerLinksColor');
        font-size: mixins.themed('footerLinksFontSize');
      }
    }

    p {
      margin: 0;
    }

    .flex-space-between {
      flex-direction: column;

      @media (min-width: 768px) {
        text-align: left;
        flex-direction: row;
      }
    }
    img {
      width: auto;
      max-height: 37px;
      margin: 20px 0;
    }
    .copyright {
      color: #a7a7a7;
      font-weight: 400;
      margin: 10px 0;
      font-size: 1.2rem;
    }
    .disclaimer {
      padding: 30px 20px;

      p {
      font-size:  0.9em;
      text-align: left;
      line-height: 1.4em;
      margin-top: 5px;
      color: #a7a7a7;
      text-align: center;

      @include mixins.themify(themes.$themes) {  
        color: mixins.themed('footerTextColor');
        font-size: mixins.themed('footerFontSize');
      }
      &:last-child:after {
        content: url("../../assets/images/18plus.png");
        width: 23px;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
  }
  .logos {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    img {
      margin: 0 15px;
    }
  }
}

  &__inner {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 10px;
  }
}
