@use  "../helpers/variables";
@use  "../helpers/mixins";

.modal {
  @include mixins.square(100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;

  &--visible {
    display: block;
  }

  &__inner {
    width: 90%;
    max-width: 876px;
    max-height: 100vh;
    padding: 20px 15px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0 auto;
    z-index: 1002;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
    text-align: center;

    &--timeout {
      padding: 0;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      max-height: auto;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      .modal__column {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

      }
    }
  }

  &__header {
    margin: 0 0 1.5rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footer {
    text-align: center;

    .cta {
      margin: 30px auto 10px;
    }
  }

  &__title {
    font-size: 2rem;
  }

  &__close-button {
    position: absolute;
    right: 0;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
    background: none;
    border: none;
  }

  &__overlay {
    @include mixins.square(100%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    display: none;
    background: #40404099;

    &--visible {
      display: block;
    }
  }

  &__body {
    height: 62vh;
    overflow-y: auto;

    &--timeout {
      height: auto;
      padding: 0 15px;
      p {
        img {
        max-width: 180px;
        margin: 20px;
      }
    }
    }

    h2 {
      margin: 0 0 15px;
    }

    h3 {
      margin: 0 0 10px;
    }

    p {
      margin: 0 0 15px;
    }
  }
}
