@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.game-preview {
  &__iframe {
    min-width: 100%;
    height: calc(100vh - 60px);
    @media (min-width: 768px) {
      height: calc(70vh);
    }
  }
}

.relaunch-game {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px;

  img {
    margin-bottom: 20px;
  }

  button {
    width: 200px;
    height: 40px;

    border: none;
    border-radius: 5px;
    
    color: #fff;
    @include mixins.themify(themes.$themes) {
      background-color: mixins.themed('ctaColor');   
    }
  }
}

.stream {
  opacity: 0
}
