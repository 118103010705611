@use  "../helpers/mixins";
@use "../helpers/themes";

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;

  @media (min-height: 610px) {
    padding-top: 200px;
  }

  &__icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-top: 4px solid;
    animation: spin .6s linear infinite;

    @include mixins.themify(themes.$themes) {
      border-color: mixins.themed('primaryColor') transparent;
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg) }
  
  100% { transform: rotate(359deg) }
}