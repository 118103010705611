@use  "../helpers/variables";
@use  "../helpers/mixins";
@use "../helpers/themes";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: scroll;

  @include mixins.themify(themes.$themes) {
    background: mixins.themed('notAvailableBPageg'); 
  }
}

.box {
  position: relative;
  width: calc(100% - 32px - 32px);
  height: auto;
  max-width: 375px;
  background: #fff;
  border-radius: 12px;
  min-width: 300px;
  padding: 16px;
  text-align: center;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 24%);

  @include mixins.themify(themes.$themes) {
    background: mixins.themed('notAvailableBoxBg'); 
  }

  &__logo {
    padding: 20px;
    max-width: 220px;
    margin: 0 auto;
    margin-bottom: 25px;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
    }

    @include mixins.themify(themes.$themes) {
      background: mixins.themed('notAvailableLogoBg'); 
    }
  }
  p {
    font-size: 1.2em;
    line-height: 1.3em;
    font-weight: 600;
    margin: 20px;
  }
}
