@use  "../helpers/mixins";
@use "../helpers/themes";

.logo {
  text-align: center;
  text-transform: uppercase;
  transition: all 0.1s ease;
  height: 48px; 

  @include mixins.themify(themes.$themes) {
    height: mixins.themed('logoHeight');
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }

  &--nohover {
    @extend .logo;

    &:hover {
      cursor: default;
    }
  }

  &--short {
    height: 40px;
    @include mixins.themify(themes.$themes) {
      height: mixins.themed('logoMobileHeight');
    }
  }

  &__image {
    height: 100%;
    float: left;
  }
}

